@import "../../bootstrap.scss";

.Result-Tabs {
    margin-top: 23px;
}

.Result-Tabs-Info-Rect {
    margin-top: 22px !important;
    padding: 0px 22px 22px 22px;
    border-radius: 4px;
    border: solid 1px #e4e9f2;
    background-color: #f7f9fc;
}

.Result-Tabs-Info-TransectNumber {
    margin-top: 22px;
    padding-left: 0px !important;
}

.Result-Tabs-Info-TransectLength,
.Result-Tabs-Info-Width,
.Result-Tabs-Info-Area,
.Result-Tabs-Info-sub-sampling {
    margin-top: 22px;
    padding-left: 22px !important;
}

.Result-Tabs-Info-TransectNumber-Input {
    height: 48px !important;
    margin-top: 7px 0 0;
    padding: 12px 10px 12px 16px;
    border-radius: 4px;
    border: solid 1px #e4e9f2;
    background-color: #fff;
}

.Result-Tabs-Info-TransectNumber-Input-Disabled {
    height: 48px !important;
    margin-top: 7px 0 0;
    padding: 12px 10px 12px 16px;
    border-radius: 4px;
    border: solid 1px #e4e9f2;
}

.Result-Tabs-Info-TransectLength-Form-Input {
    width: 71px;
    height: 48px;
    margin: 0 6px 0 0;
    border-radius: 4px;
    border: solid 1px #e4e9f2;
    background-color: #fff;
}

.Result-Tabs-Info-TransectLength-And {
    font-family: SpoqaHanSans;
    margin-top: 12px;
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #909090;
}

.Result-Tabs-Info-TransectLength-M {
    margin: 12px 6px;
    font-family: SpoqaHanSans;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #000;
}


.Result-Tabs-Info-TransectLength-To-Input {
    width: 71px;
    height: 48px;
    margin: 0 8px;
    border-radius: 4px;
    border: solid 1px #e4e9f2;
    background-color: #fff;
}

.Result-Tabs-Info-Sub-Sampling-Select {
    height: 48px !important;
    margin-top: 7px 0 0;
    padding: 12px 10px 12px 16px;
    border-radius: 4px;
    border: solid 1px #e4e9f2;
    background-color: #fff;
}

/* .table-condensed {
    font-size: 1rem;
} */

@media screen and (max-width: 992px) {

    /* lg */
    /* .table-condensed {
        font-size: 1rem;
    } */
}

@media screen and (max-width: 768px) {

    /* md */
    /* .table-condensed {
        font-size: 0.8rem;
    } */
}

@media screen and (max-width: 576px) {

    /* sm */
    /* .table-condensed {
        font-size: 0.7rem;
    } */
}

.d-none-on-md-25 {
    display: none;
    margin: 0px;
    padding: 0px;
}

@include media-breakpoint-down(sm) {
    .Result-Tabs-Info-Rect {
        margin: unset;
        padding: unset;
        border-radius: unset;
        border: unset;
        background-color: unset;
    }

    .Result-Tabs-Info-TransectNumber {
        margin-top: 24px;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .Result-Tabs-Info-TransectLength,
    .Result-Tabs-Info-Width,
    .Result-Tabs-Info-Area {
        margin-top: 20px;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .d-none-on-md-25 {
        margin-top: 25px;
        margin-bottom: 25px;
        padding: 0x;
        display: block;
    }
}