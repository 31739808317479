.container-create-member {
    position: relative;
    top: 15%;
    width: 343px;
    left: calc(50% - 171px);
    font-family: 'Spoqa Han Sans';
}

#create_member_radio {
    font-family: 'Spoqa Han Sans';
    font-size: 14px;
    font-weight: bold;
}

#custom_radio_btn {
    color: #35c4f0 !important;
}

#create_member_user_id {
    margin-top: 17px;    
    margin-bottom: 15px;
}

#create_member_confirm {
    color: #718096 !important;
    font-family: 'Spoqa Han Sans' !important;
    background-color: #f5f9fc !important;
    border-color: #e4e9f2 !important;

    height: 48px;
    margin-top: 17px;    
    margin-bottom: 30px;
    font-size: 14px;
    font-weight: bold;
}

#create_member_first_name, #create_member_last_name, #create_member_password, #create_member_confirm_password {
    font-family: 'Spoqa Han Sans';
    font-size: 16px;
    margin-top: 17px;    
}