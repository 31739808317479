.container-border {
    border: 1px solid black;
}

.link_row {
    background: rgba(0,0,0, 0.6);
}

/* On screens that are less than 992px wide */
@media (max-width: 992px) {
    .div-home-body {
        position: relative; 
        min-height: 237px;              /* origianl: 437px, but in landscape(or responsive design), not proper size */
        height: calc(100vh - 350px);    /* exclude footer(286px) + navbar(64px) height */
    }

    .div-home-body-message {
        font-family: 'Noto Sans KR';
        font-size: 21px;
        font-weight: bold;
        color: white;

        position: relative;
        padding-top: 70px;  /* original: 117px but in landscape(or responsive design), not proper position */
        padding-left: 28px;
        padding-right: 28px;
        text-align: center;
    }

    .div-home-body-monitoring-button {
        font-family: 'Noto Sans KR';
        font-size: 14px;
        font-weight: bold;
        color: white;
        border: 1px solid white;

        position: relative;
        width: 180px;   /* original size: 164px but increased because text is 'Monitoring results' */
        height: 41px;
        margin-top: 28.5px;
        left: calc(50% - 90px);
    }
    
    #home_monitoring_result1 {
        margin-left: 18px;
        margin-top: 11px;
        margin-bottom: 10px;
        align-items: center;
        float: left;
    }
    
    #home_monitoring_result2 {
        margin-right: 14px;
        margin-top: 13px;
        margin-bottom: 10px;
        float: right;
    }

    #div-home-footer {
        width: 100%;
        position: relative;
        height: 286px; /* div-home-download + div-home-link  */
    }

    #div-home-download {
        position: relative;
        /* border: 1px solid blue; */
        width: 100%;
        height: 156px;    /* 52 * 4 = 208px, 52 * 3 = 156px */
        margin-left: 0px;
        margin-right: 0px;
        /* vertical-align: middle !important; */
    }

    #div-home-link {
        position: relative;
        width: 100%;
        height: 130px;
        margin-left: 0px;
        margin-right: 0px;
        background-color: white;
    }    

    .link_col {
        height: 52px;
        border: 1px solid black;
    }

    .link_label {
        font-family: 'Noto Sans KR';
        font-size: 14px;
        font-weight: bold;
        color: white;
        margin-left: 20px;
        margin-top: 16px;
        margin-bottom: 16px;
        float: left;
    }
    
    .link_download_button,
    .link_play_button {
        width: 24px;
        height: 24px;
        margin-right: 16px;
        margin-top: 14px;
        margin-bottom: 14px;
        float:right;
        
    }
    
    .img-banner {
        object-fit: cover;
        width: 120px !important;
        height: 45px !important;
    }
}

/* On screens that are more than 992px wide */
@media (min-width: 992px) {
    .div-home-body {
        position: relative; 
        min-height: 514px;              /* origianl: 714px, but in responsive design, not proper size */
        height: calc(100vh - 260px);    /* exclude footer(200px) + navbar(60px) height */
    }

    .div-home-body-message {
        font-family: 'Noto Sans KR';
        font-size: 42px;
        font-weight: bold;
        color: white;

        position: relative;
        padding-top: 107px;  /* original: 107px but in landscape(or responsive design), not proper position */
        padding-left: 28px;
        padding-right: 28px;
        text-align: center;
        
        width: 720px;
        left: calc(50% - 360px);

    }

    .div-home-body-monitoring-button {
        font-family: 'Noto Sans KR';
        font-size: 16px;
        font-weight: bold;
        color: white;
        border: 1px solid white;

        position: relative;
        width: 230px;   /* original size: 164px but increased because text is 'Monitoring results' */
        height: 50px;
        margin-top: 28.5px;
        left: calc(50% - 90px);
    }
    
    #home_monitoring_result1 {
        margin-left: 18px;
        margin-top: 12px;
        margin-bottom: 14px;
        align-items: center;
        float: left;
    }
    
    #home_monitoring_result2 {
        margin-right: 14px;
        margin-top: 17px;
        margin-bottom: 18px;
        float: right;
    }

    #div-home-footer {
        width: 100%;
        position: relative;
        height: 200px;
    }

    #div-home-download {
        position: relative;
        /* border: 1px solid blue; */
        width: 100%;
        height: 93px;
        margin-left: 0px;
        margin-right: 0px;
        /* vertical-align: middle !important; */
    }

    #div-home-link {
        position: relative;
        width: 100%;
        height: 108px;
        margin-left: 0px;
        margin-right: 0px;
        background-color: white;
    }    

    .link_col {
        display: flex;              /* should set flex and align-items: center to set vertical center */
        align-items: center;
        justify-content: center;    /* horizontal center */
        
        height: 93px;
        border: 1px solid black;
    }

    .link_label {
        font-family: 'Noto Sans KR';
        font-size: 20px;
        font-weight: bold;
        color: white;
    }

    .link_download_button {
        width: 30px;
        height: 30px;
        margin-left: 12px;
    }

    .link_play_button {
        margin-left: 12px;
    }

    .img-banner {
        width: 142px !important;
        height: 53px !important;
    }
}

