@import "../../bootstrap.scss";

.EtcUpload-Input-Text {
    width: 124px;
    height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.EtcUpload-Input {
    width: 124px;
    height: 48px;
    border-radius: 5px;
    border: solid 1px #e4e9f2;
    background-color: #fff;
    position: relative;
    text-align: center;
    overflow: hidden;
    vertical-align: middle;
}


.EtcUpload-Input input {
    cursor: pointer;
    opacity: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0
}

.EtcUpload-List {
    overflow: hidden;
    overflow-y: auto;
    height: 120px;
    margin-right: 10px;
    padding-left: 19.7px;
    padding-top: 17px;
    border-radius: 4px;
    border: solid 1px #e4e9f2;
    background-color: #fff;
}


.EtcUpload-List-Text {
    font-family: SpoqaHanSans;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #606060;
    padding-bottom: 10px;
}

.etc-fit-width {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

@include media-breakpoint-down(sm) {
    .EtcUpload-Input {
        width: 100%;
        height: 48px;
        border-radius: 5px;
        border: solid 1px #e4e9f2;
        background-color: #fff;
        position: relative;
        text-align: center;
        overflow: hidden;
        vertical-align: middle;
    }
    
    .EtcUpload-List {
        overflow: hidden;
        overflow-y: auto;
        height: 120px;
        margin-top: 10px;
        margin-right: unset;
        padding-left: 19.7px;
        padding-top: 17px;
        border-radius: 4px;
        border: solid 1px #e4e9f2;
        background-color: #fff;
    }
}