.container-logo {
    display: flex;
    text-align: center;
    position: relative;
    padding-top: 140px;
    width: 353px !important;
    font-family: 'Noto Sans KR';
    color:white;
    font-size: 20px;
    font-weight: bold;
    align-items: center;
 }

 #logo_image {
     display: inline-block;
     margin-right: 10px;
 }

 #logo_text {
    display: inline-block;
    white-space: pre-wrap;
    text-align: left;
 }

.container-login {
    position: relative;
    margin-top: 28px;
    width: 335px !important;
    height: 375px;
    font-family: 'Spoqa Han Sans';
    border-radius: 5px;
}

#login_title {
    color: black;
    font-size: 24px;
    font-weight: bold;
    padding-top: 31px;
    padding-bottom: 30px;
}

.login_small_text_info {
    font-size: 12px;
    font-weight: bold;
    color: #8f9bb3;
}

.login_small_text_error {
    font-size: 12px;
    margin-top: 6px;
    margin-bottom: 6px;
    color: red;
}
