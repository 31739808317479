.pagination {
    border: 0px solid #ddd;
}

.pagination>li>a {
    border: 0px solid #ddd;
    color: unset !important;
    border-radius: 0px !important;

    padding: 3px 8px;
    display: block;
    min-width: 24px;
    min-height: 24px;
    text-align: center;
}

.pagination>li>span {
    border: 0px solid #ddd;
    border-radius: 0px !important;
    background-color: unset !important;

    padding: 3px 8px;
    display: block;
    min-width: 24px;
    min-height: 24px;
    text-align: center;
}

.pagination>li {
    margin: 0 4px 0 0 !important;
}

.pagination>li.selected {
    border-radius: 3px !important;
    background-color: #fafafa !important;
}

.page-link:hover {
    border-color: unset !important;
    background-color: #fafafa !important;
}
