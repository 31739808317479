@import "../../bootstrap.scss";

.Result-Tabs-Info-TransectNumber-Label,
.Result-Tabs-Info-TransectLength-Label,
.Result-Material-Weight-Label,
.Result-Material-Width-Label,
.Result-Material-Area-Label,
.Result-Material-Sub-Sampling-Label {
    font-family: 'Spoqa Han Sans' !important;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: #8f9bb3;

    display: flex;
    align-self: center;
}

.Result-Material-Text {
    margin-top: 32px;
    font-family: "Noto Sans KR" !important;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #000;
}

.Result-Material-Weight-Rect {
    margin-top: 11px;
    margin-bottom: 22px;
    padding: 14px 16px 14px 22px;
    border-radius: 4px;
    border: solid 1px #e4e9f2;
    background-color: #f7f9fc;
}

.Result-Material-Weight {
    padding-left: 16px !important;
    padding-right: 0px !important;
}


.Result-Tabs-Material-Item-Input {
    width: 120px !important;
    height: 35px !important;
    border-radius: 4px;
    border: solid 1px #e4e9f2;
    background-color: #fff;

    font-family: 'Spoqa Han Sans' !important;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.07;
    letter-spacing: normal;
    color: #909090;
}


.Result-Tabs-Material-Data-Column-Items {
    width: 100%;
    max-width: 234px;
    text-align: left;
    padding-top: 5px;
    padding-bottom: 7px;
    padding-left: 12px;
    padding-right: 12px;

    text-align: left;
    font-family: 'Spoqa Han Sans' !important;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #718096;
}

.Result-Tabs-Material-Item-Column-Number {
    width: 100% !important;
    max-width: 144px !important;
    text-align: left !important;
    padding-top: 5px;
    padding-bottom: 7px;
    padding-left: 12px;
    padding-right: 12px;

    text-align: left;
    font-family: 'Spoqa Han Sans' !important;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #718096;
}

.Result-Tabs-Material-Item-Row {
    border-bottom: 1px solid #eff4fb;
}


.Result-Tabs-Material-Item-Item {
    margin-left: 12px;

    padding: 13px 20px 14px 0px;
    width: 100% !important;
    text-align: left;

    font-family: 'Spoqa Han Sans' !important;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #454545;

    display: flex;
    align-self: center;
}

.Result-Tabs-Material-Item-Number {
    width: 100%;
    max-width: 144px;

    padding-top: 6px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    padding-bottom: 7px !important;

    text-align: left;
    font-family: 'Spoqa Han Sans' !important;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #454545;
}

input.Result-Tabs-Material-Item-Input::placeholder {
    font-family: 'Spoqa Han Sans' !important;
    font-size: 14px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 2.07 !important;
    letter-spacing: normal !important;
    color: #909090 !important;
}

.Result-Tabs-Material-Data-SubTitle-Rect {
    margin-top: 22px;
    font-family: "Noto Sans KR";
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.69;
    letter-spacing: normal;
    color: #000;
}

.Result-Tabs-Material-Data-Columns {
    padding: 10px 0px 0px 0px;
    border-bottom: 1px solid #e4e9f2;
}

@include media-breakpoint-down(sm) {
    .Result-Material-Text {
        margin-top: 0px;
        font-family: "Noto Sans KR" !important;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #000;
    }

    .Result-Material-Weight-Rect {
        margin-top: 10px;
        margin-bottom: 0px;
        padding: 8px 14px 7px 20px !important;
        border-radius: 4px;
        border: solid 1px #e4e9f2;
        background-color: #f7f9fc;
    }

    .Result-Material-Weight-Label {
        font-family: 'Spoqa Han Sans' !important;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: normal;
        color: #8f9bb3;

        display: flex;
        align-self: center;
    }

    .Result-Material-Weight {
        padding-left: 16px !important;
        padding-right: 0px !important;
    }

    .Result-Tabs-Material-Data-SubTitle-Rect {
        margin-top: 20px;
        font-family: "Noto Sans KR";
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.69;
        letter-spacing: normal;
        color: #000;
    }

    .Result-Tabs-Material-Data-Columns {
        padding-top: 11px 0px 0px 0px;
        border-bottom: 1px solid #e4e9f2;
    }

    .Result-Tabs-Material-Data-Column-Items {
        width: 100%;
        max-width: 234px;
        text-align: left;
        padding-left: 12px;
        padding-right: 12px;

        text-align: left;
        font-family: 'Spoqa Han Sans' !important;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #718096;
    }

    .Result-Tabs-Material-Item-Column-Number {
        width: 100%;
        max-width: 94px !important;
        text-align: left;
        padding-left: 12px;
        padding-right: 12px;

        text-align: left;
        font-family: 'Spoqa Han Sans' !important;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #718096;
    }

    .Result-Tabs-Material-Item-Item {
        padding: 13px 20px 14px 0px;
        width: 100%;
        // max-width: 234px;
        text-align: left;

        font-family: 'Spoqa Han Sans' !important;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #454545;

        display: flex;
        align-self: center;
    }

    .Result-Tabs-Material-Item-Number {
        width: 100%;
        max-width: 94px;
        padding: 6px !important;

        text-align: left;
        font-family: 'Spoqa Han Sans' !important;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #454545;
    }

    .Result-Tabs-Material-Item-Input {
        width: 82px !important;
        height: 35px !important;
        margin: 6px 6px 7px 6px;
        padding: 3px 58px 3px 10px;
        border-radius: 4px;
        border: solid 1px #e4e9f2;
        background-color: #fff;

        font-family: 'Spoqa Han Sans' !important;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.07;
        letter-spacing: normal;
        color: #909090;
    }

}