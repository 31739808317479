.App {
  text-align: left;
  /* min-height: 400px; */
}

#image-background {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css);

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sub-container {
    /* border: 1px solid black; */
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 16px;
    margin-bottom: 16px;
}

.text-statement {
    color: red;
    text-align: left;
    display: inline-block;
}
  

/* https://stackoverflow.com/questions/37902708/google-charts-tooltip-flickering/39775254#39775254
svg > g:last-child > g:last-child { pointer-events: none }
div.google-visualization-tooltip { pointer-events: none } 
*/
svg > g > g.google-visualization-tooltip { pointer-events: none }


.btn-primary {
    font-family: 'Spoqa Han Sans' !important;
    background-color: #35c4f0 !important;
    border-color: #35c4f0 !important;
}

.btn-primary:hover {
    font-family: 'Spoqa Han Sans' !important;
    background-color: #249cc1 !important;
    border-color: #249cc1 !important;
}

.btn-primary:disabled {
    font-family: 'Spoqa Han Sans' !important;
    color: #bdbdbd !important;
    background-color: #f2f2f2 !important;
    border-color: #f2f2f2 !important;
}

.btn-link {
    font-family: 'Spoqa Han Sans' !important;
    font-size: 14px !important;
    color: #35c4f0 !important;
}

.btn-link2 {
    font-family: 'Spoqa Han Sans' !important;
    font-size: 14px !important;
    color: #fb3b3b !important;
}


.image-view-modal-wrapper {
  background-color: #000000;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: var(--show);
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.image-view-modal-image {
  max-width: 100%;
  max-height: var(--max-height);
  width: auto;
  height: auto;
  object-fit: contain;
}

.image-view-modal-op-btn {
  width: var(--size);
  height: var(--size);
  cursor: pointer;
  position: absolute;
  margin: auto;
  top: 0; 
  left: 0; 
  bottom: 0; 
  right: 0;
  object-fit: contain;
  z-index: 1060;
}

.image-view-modal-exit-btn {
  width: var(--size);
  height: var(--size);
  cursor: pointer;
  position: absolute;
  right: 0px;
  margin-right: 20px;
  margin-top: 20px;
  object-fit: contain;
  z-index: 1060;
}

.image-view-modal-counter{
  position: absolute;
  width: 48px;
  height: 33px;
  opacity: 0.8;
  border-radius: 16.5px;
  background-color: #000;
  bottom: 43px;
  z-index: 1060;
  /* right : var(--right); */
  right : 0;
  left : 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.image-view-modal-counter-number{
  width: 19px;
  height: 18px;
  font-family: 'Noto Sans CJ Kkr';
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  display: inline-block;
  padding-top: 7px;
}