#custom-add-btn {
    font-family: 'Spoqa Han Sans' !important;
    font-size: 14px;
    font-weight: bold;
    background-color: #f5f9fc !important;
    border-color: #e4e9f2 !important;
    width: 82px;
    float: right;
}

#custom-close-button {
    width: 24px;
    height: 24px;
    background-image: url(../../images/icon-24-24-px-close-red-line.png);
}

#custom-save-button {
    width: 201px;
    height: 48px;
    padding-top: 11px;
    font-family: 'Spoqa Han Sans';
    font-size: 16px;
    font-weight: bold;
} 