// @import "../../bootstrap.scss";

// .preview-basic-info {
//     font-size: 1rem;
// }

// @media screen and (max-width: 992px) {

//     /* lg */
//     .preview-basic-info {
//         font-size: 1rem;
//     }
// }

// @media screen and (max-width: 768px) {

//     /* md */
//     .preview-basic-info {
//         font-size: 0.8rem;
//     }
// }

// @media screen and (max-width: 576px) {

//     /* sm */
//     .preview-basic-info {
//         font-size: 0.7rem;
//     }

//     .preview-h5 {
//         font-size: 1rem;
//     }

//     .preview-h6 {
//         font-size: 0.9rem;
//     }
// }

.PreviewMonitoring-Header-Rect {
    padding: 20px !important;
}

.PreviewMonitoring-Close-Btn {
    margin: 10px 0px 42px 0px !important;
    padding: 12px 78px 12px 80px;
    border-radius: 4px;
    background-color: #35c4f0;
}

.PreviewMonitoring-Modal-Body-Rect {
    padding: 24px !important;
}

.hr-mt-12px {
    margin-top: 12px;
}

.PreviewMonitoring-Body-BasicInfo {
    margin-top: 24px;
    margin-bottom: 10px;
}

.PreviewMonitoring-Body-BasicInfo-Item-Col {
    padding-bottom: 14px;
}

.PreviewMonitoring-Body-Weather-Rect {
    padding-top: 30px !important;
}

.PreviewMonitoring-Body-Weather-Row,
.PreviewMonitoring-Body-DetailInformation-Row {
    padding-top: 6px !important;
}

.PreviewMonitoring-Result-Title {
    margin-top: 67px;
}

.PreviewMonitoring-Result-Tab-Information {
    margin-top: 0px;
    border-top: unset;
    border-bottom: 1px solid #e4e9f2;
    padding-top: 0px;
    padding-bottom: 24px;
}

.MonitoringResult-Material-Text-Row {
    padding-top: 24px;
}

.MonitoringResult-Material-Weight-Rect {
    padding: 15px 20px 14px 20px !important;
    margin-top: 10px;
    border-radius: 4px;
    border: solid 1px #e4e9f2;
    background-color: #f7f9fc;
}

.MonitoringResult-Type-Text-Col {
    margin-top: 20px;   
}

.MonitoringResult-Item-Header-Row {
    margin-top: 10px !important;
    padding: 5px 12px 7px 12px !important;
    border-bottom: 1px solid #e4e9f2;
}

.MonitoringResult-Item-Value-Col {
    padding-left: 12px;
    width: 94px !important;
}

.MonitoringResult-Item-Row {
    padding: 13px 12px 14px 12px !important;
    border-bottom: 1px solid #eff4fb;
}

.MonitoringResult-Material-Hr {
    margin-top: 40px;
    margin-bottom: 16px;
}

.PreviewMonitoring-Result-Info-Column {
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-left: 0px !important;
    // padding-right: 75px !important;
    text-align: start;
}

/* hr with style */
hr.hr-text {
    position: relative;
    border: none;
    height: 3px;
    background: #999;
}

hr.hr-text::before {
    content: attr(data-content);
    display: inline-block;
    background: #fff;
    font-weight: bold;
    font-size: 0.85rem;
    color: #999;
    border-radius: 30rem;
    padding: 0.2rem 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}