@import "../../bootstrap.scss";

.SubmitMonitoring-Text {
    margin-top: 36px;
    font-family: 'Spoqa Han Sans' !important;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #303030;
}

.Information-Text {
    margin-top: 45px;
    font-family: 'Spoqa Han Sans' !important;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #303030;
}

.Information-Rect {
    margin-top: 14px;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 4px;
    border: solid 1px #e4e9f2;
    background-color: #f7f9fc;
}

.Information-Date-Col,
.Information-Time-Col,
.Information-Site-Col {
    padding-left: 13px !important;
    padding-right: 13px !important;
}

.Overview-ImageUploadComponent-Col {
    padding-left: 13px !important;
    padding-right: 24px !important;
}

.Overview-MonitoringCard-Col {
    padding-left: 13px !important;
    padding-right: 24px !important;
}

.Overview-MonitoringCard-Col-End {
    padding-left: 13px !important;
    padding-right: 0px !important;
}

.Information-Date-Label,
.Information-Time-Label,
.Information-Site-Label,
.Information-Organization-Label,
.Information-Manager-Label,
.Information-NumberOfParticipant-Label,
.Information-Temperature-Label,
.Information-WindDirection-Label,
.Information-WindSpeed-Label,
.Information-Weather-Label,
.Information-LatestTimeOfHighTide-Label,
.Information-Longitude-Label,
.Information-Latitude-Label,
// .Information-Others-Label,
.Photos-Before-Label,
.Photos-After-Label,
.Photos-Etc-Label,
.Photos-Transect-Label {
    font-family: 'Spoqa Han Sans' !important;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: #8f9bb3;
}

.Photos-Transect-Gap {
    padding-top: 8px !important;;
    padding-left: 0px !important;
    padding-right: auto !important;
}

.Photos-Transect-Image-Group {
    padding-left: 0px;
    padding-right: 40px;
}

.Information-Date-Input,
.Information-Time-Input,
.Information-Site-Input,
.Information-Organization-Input,
.Information-Manager-Input,
.Information-NumberOfParticipant-Input,
.Information-Temperature-Input,
.Information-WindDirection-Input,
.Information-WindSpeed-Input,
.Information-Weather-Input,
.Information-LatestTimeOfHighTide-Input,
.Information-Longitude-Input,
.Information-Latitude-Input {
// .Information-Others-Input {
    height: 48px !important;
    margin-top: 7px 0 0;
    padding: 12px 10px 12px 16px;
    border-radius: 4px;
    border: solid 1px #e4e9f2;
    background-color: #fff;
}

.Information-Time-And {
    font-family: 'Spoqa Han Sans' !important;
    margin-top: 12px;
    margin-bottom: 12px;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #909090;
}

.Photos-Text,
.Result-Text {
    margin-top: 70px;
    font-family: 'Spoqa Han Sans' !important;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #303030;
}

.Overview-PhotosOfBeach-Text {
    margin-top: 20px;
    font-family: "Noto Sans KR" !important;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.69;
    letter-spacing: normal;
    color: #000;
}

.Overview-TransectPhotos-Text,
.Overview-MonitoringCard-Text {
    margin-top: 24px;
    font-family: "Noto Sans KR" !important;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.69;
    letter-spacing: normal;
    color: #000;
}

.Overview-PhotosOfBeach-Rect,
.Overview-MonitoringCard-Rect {
    margin-top: 10px;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 13px;
    padding-right: 13px;
    border-radius: 4px;
    border: solid 1px #e4e9f2;
    background-color: #f7f9fc;
}

.Overview-TransectPhotos-Rect {
    margin-top: 10px;
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 4px;
    border-radius: 4px;
    border: solid 1px #e4e9f2;
    background-color: #f7f9fc;
}

.Photos-Transect-Group {
    padding-bottom: 20px;
}

.Submit-Bottom-HR {
    margin-top: 40px;
    margin-bottom: 37px;
}

.transect-td-w {
    width: 20%;
}

.transect-td-min-w {
    min-width: 10em;
}

.transect-tbl {
    table-layout: fixed;
}

.submit-monitoring-section-title {
    text-align: left;
}

#submit-monitoring-section div.form-row {
    text-align: left;
}

.explicit-spin::-webkit-inner-spin-button {
    opacity: 1 !important;
}

.c-invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

.d-none-on-md {
    display: none;
    margin: 0px;
    padding: 0px;
}

.Form-Submit-Button-Group {
    margin-bottom: 166px;
}

.SaveTemporarily-Btn {
    width: 201px !important;
    max-width: 201px;

    padding-top: 12px !important;
    padding-right: 31.5px;
    padding-bottom: 12px !important;
    padding-left: 33.5px;

    height: 48px !important;
    border-radius: 4px;
    border: solid 1px #e4e9f2 !important;
    background-color: #f5f9fc !important;

    font-family: 'Spoqa Han Sans' !important;
    font-size: 16px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    text-align: center !important;
    color: #718096 !important;
}

.Preview-Btn {
    width: 201px !important;
    max-width: 201px;
    height: 48px;

    padding-top: 12px !important;
    padding-bottom: 12px !important;
    padding-left: 69px;
    padding-right: 67px;
    margin-right: 9px;

    border-radius: 4px;
    border: solid 1px #e4e9f2 !important;
    background-color: #f5f9fc !important;

    font-family: 'Spoqa Han Sans' !important;
    font-size: 16px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    text-align: center !important;
    color: #718096 !important;
}

.Submit-Btn {
    width: 201px !important;
    max-width: 201px;
    height: 48px;

    padding-top: 12px !important;
    padding-bottom: 12px !important;
    padding-left: 72px;
    padding-right: 70px;

    border-radius: 4px;
    background-color: #fff;

    font-family: 'Spoqa Han Sans' !important;
    font-size: 16px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    text-align: center !important;
    color: #fff !important;
}


.upload-state-modal .modal-content {
    height: auto;
    overflow-y:visible;
}

.upload-state-modal-header {
    font-family: 'Spoqa Han Sans' !important;
    font-size: 16px;
    font-weight: bold;
}

.upload-state-modal-body {
    font-family: 'Spoqa Han Sans' !important;
    font-size: 14px;
    color: #6b6b6b;
}

@include media-breakpoint-down(sm) {
    .Information-Rect {
        margin-top: 14px;
        padding-top: unset;
        padding-bottom: unset;
        padding-left: 0px;
        padding-right: 0px;
        border-radius: unset;
        border: unset;
        background-color: unset;
    }

    .Information-Date-Col,
    .Information-Time-Col,
    .Information-Site-Col {
        padding-left: 0px;
        padding-right: 0px;
    }

    .Photos-Text,
    .Result-Text {
        margin-top: 40px;
        font-family: 'Spoqa Han Sans' !important;
        font-size: 22px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #303030;
    }

    .Overview-PhotosOfBeach-Text {
        margin-top: 30px;
        font-family: "Noto Sans KR" !important;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.69;
        letter-spacing: normal;
        color: #000;
    }

    .Overview-MonitoringCard-Text {
        margin-top: 40px;
        font-family: "Noto Sans KR" !important;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.69;
        letter-spacing: normal;
        color: #000;
    }

    .Overview-TransectPhotos-Text {
        margin-top: 40px;
        font-family: "Noto Sans KR" !important;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.69;
        letter-spacing: normal;
        color: #000;
    }

    .Overview-PhotosOfBeach-Rect,
    .Overview-MonitoringCard-Rect {
        padding: 0px;
        border-radius: unset;
        border: unset;
        background-color: unset;
    }

    .Overview-ImageUploadComponent-Col {
        padding-left: 0px !important;
        padding-right: 0px !important;
        margin-top: 10px !important;
    }

    .Overview-TransectPhotos-Rect {
        margin-top: 10px;
        padding: unset;
        border-radius: unset;
        border: unset;
        background-color: unset;
    }

    .Photos-Transect-Gap {
        padding-top: 8px !important;
        padding-left: 0px !important;
        padding-right: 3px !important;
    }

    .Photos-Transect-Group {
        padding-bottom: unset;
        padding-top: 16px;
    }

    .Photos-Transect-Image-Group {
        padding-left: 0px;
        padding-right: 0px;
    }

    .d-none-on-md {
        margin-top: 40px;
        margin-bottom: 0px;
        padding: 0x;
        display: block;
    }

    .Result-Tabs .nav-link {
        margin-right: 13px !important;
    }

    .Submit-Bottom-HR {
        margin-top: 40px;
        margin-bottom: 34px;
    }

    .SaveTemporarily-Btn {
        width: 100% !important;
        max-width: unset;

        height: 48px !important;
        padding-top: 12px;
        padding-bottom: 12px;
        border-radius: 4px;
        border: solid 1px #e4e9f2 !important;
        background-color: #f5f9fc !important;

        font-family: 'Spoqa Han Sans' !important;
        font-size: 16px !important;
        font-weight: bold !important;
        font-stretch: normal !important;
        font-style: normal !important;
        line-height: normal !important;
        letter-spacing: normal !important;
        text-align: center !important;
        color: #718096 !important;
    }

    .Preview-Btn {
        width: 100%;
        max-width: 160px;
        height: 48px;

        margin-top: 9px;
        padding: 12px 0px;
        border-radius: 4px;
        border: solid 1px #e4e9f2 !important;
        background-color: #f5f9fc !important;

        font-family: 'Spoqa Han Sans' !important;
        font-size: 16px !important;
        font-weight: bold !important;
        font-stretch: normal !important;
        font-style: normal !important;
        line-height: normal !important;
        letter-spacing: normal !important;
        text-align: center !important;
        color: #718096 !important;
    }

    .Submit-Btn {
        width: 100%;
        max-width: 160px;
        height: 48px;

        margin-top: 9px;
        padding: 12px 0px;
        border-radius: 4px;
        background-color: #fff;

        font-family: 'Spoqa Han Sans' !important;
        font-size: 16px !important;
        font-weight: bold !important;
        font-stretch: normal !important;
        font-style: normal !important;
        line-height: normal !important;
        letter-spacing: normal !important;
        text-align: center !important;
        color: #fff !important;
    }

    .Form-Submit-Button-Group {
        margin-bottom: 74px;
    }

    .Overview-MonitoringCard-Col {
        padding-left: 0px !important;
        padding-right: 4px !important;
    }

    .Overview-MonitoringCard-Col-End {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

.Import-Excel-Ver2 {
    width: 100%;
    max-width: 180px;
    height: 48px;

    margin-top: 45px;
    margin-left: 50%;
    padding: 15px px;
    border-radius: 4px;

    font-family: 'Spoqa Han Sans' !important;
    font-size: 16px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    text-align: center !important;

    color: #fff !important;
    background-color: #35c4f0 !important;
    border-color: #35c4f0 !important;
}
.Clear {
    width: 100%;
    max-width: 180px;
    height: 48px;

    margin-top: 45px;
    margin-left: 13%;
    padding: 12px 0px;
    border-radius: 4px;

    font-family: 'Spoqa Han Sans' !important;
    font-size: 16px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    text-align: center !important;

    color: #fff !important;
    background-color: #35c4f0 !important;
    border-color: #35c4f0 !important;
}