// ref: https://getbootstrap.com/docs/4.0/getting-started/theming/

// Your variable overrides
$enable-validation-icons: false;

// Bootstrap and its default variables
@import "node_modules/bootstrap/scss/bootstrap";

/** fonts **/
.SpoqaHanSans-b-12 {
    font-family: 'Spoqa Han Sans' !important;
    font-size: 12px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.5 !important;
    letter-spacing: normal !important;
}

.SpoqaHanSans-r-14 {
    font-family: 'Spoqa Han Sans' !important;
    font-size: 14px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.5 !important;
    letter-spacing: normal !important;
}

.SpoqaHansSans-b-16 {
    font-family: 'Spoqa Han Sans' !important;
    font-size: 16px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
  }

  .SpoqaHanSans-r-16 {
    font-family: 'Spoqa Han Sans' !important;
    font-size: 16px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.5 !important;
    letter-spacing: normal !important;
  }
  
  .SpoqaHansSans-b-22 {
    font-family: 'Spoqa Han Sans' !important;
    font-size: 22px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
  }
  
  .NotoSansCJKkr-r-12 {
    font-family: "Noto Sans KR" !important;
    font-size: 12px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.25 !important;
    letter-spacing: normal !important;
  }

  .NotoSansCJKkr-b-16 {
    font-family: "Noto Sans KR" !important;
    font-size: 16px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.69 !important;
    letter-spacing: normal !important;
  }

  .NotoSansCJKkr-r-16 {
    font-family: "Noto Sans KR" !important;
    font-size: 16px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.69 !important;
    letter-spacing: normal !important;
  }

  .NotoSansCJKkr-b-18 {
    font-family: "Noto Sans KR" !important;
    font-size: 18px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.5 !important;
    letter-spacing: normal !important;
  }

  /** colors **/
  .c-000 {
    color: #000;
  }
  
  .c-fff {
    color: #fff;
  }
  
  .c-303030 {
    color: #303030;
  }

.c-454545 {
    color: #454545;
}

.c-718096 {
    color: #718096;
  }

  .c-8f9bb3 {
    color: #8f9bb3;
  }

  .c-e4e9f2 {
      color: #e4e9f2;
  }

  .c-2d3748 {
    color: #2d3748;
  }

  @include media-breakpoint-down(sm) {
    .Monitoring-List-Rectangle {
        height: 175px;
    }

    .Monitoring-List-Container-Rect {
        padding: 20px;
    }

    .MonitoringResultTitle {
        padding-top: 80px;
        position: relative;
        z-index: 5;

        font-family: "Noto Sans KR" !important;
        font-size: 24px !important;
        font-weight: bold !important;
        font-stretch: normal !important;
        font-style: normal !important;
        line-height: 2 !important;
        letter-spacing: normal !important;
        
        color: #fff;
    }

    .MonitoringResult-Tab-Chart {
        padding-top: 28px;
    }
    
    .MonitoringResult-Tab-Chart-Legend {
        padding-top: 21px;
        height: 100px;
        margin-left: auto;
        margin-right: auto;
    }

    .PreviewMonitoring-Body-BasicInfo-Item-Col {
        padding-bottom: 19px;
    }

    .PreviewMonitoringTabs .nav-tabs .nav-link {
        border: none;
        border-bottom: 3px solid transparent;
        margin-top: 0px;
        margin-right: 4px;
        padding-top: 0px;
        padding-left: 0px;
        padding-right: 6px;
        font-family: 'Spoqa Han Sans' !important;
        font-size: 13px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
        color: #000;
        text-align: center;
    }

    .MonitoringResultTabs .nav-tabs .nav-link {
        border: none;
        border-bottom: 3px solid transparent;
        margin-top: 0px;
        margin-right: 10px;
        padding-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
        font-family: 'Spoqa Han Sans' !important;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #000;
    }
    
    
    .PreviewMonitoring-Result-Info-Column {
        margin-left: 0px !important;
        margin-right: 0px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }    

    .MonitoringResult-Material-Weight-Rect {
        padding: 15px 18px 14px 18px !important;
        margin-top: 10px;
        border-radius: 4px;
        border: solid 1px #e4e9f2;
        background-color: #f7f9fc;
    }

    .MonitoringResult-Type-Text-Col {
        margin-top: 14px;   
    }

    .MonitoringResult-Item-Value-Col {
        padding-left: 12px;
        width: 71px !important;
    }
}