// @import "node_modules/bootstrap/scss/bootstrap";

@import "../../bootstrap.scss";

/* .title-img {
    width: 100%;
    object-fit: cover;
    overflow: hidden;
} */

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    /* color: #495057; */
    /* background-color: #fff; */
    border: none;
    border-bottom: 3px solid #35c4f0;
}

.nav-tabs .nav-link {
    border: none;
    border-bottom: 3px solid transparent;
    margin-top: 0px;
    margin-right: 19px;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    font-family: 'Spoqa Han Sans' !important;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #000;
}

.Monitoring-Container {
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto;
}

.Monitoring-Container-Main {
    // margin-left: 20px;
    // margin-right: 20px;
    width: 100%;
}

#title-img.Rectangle {
    min-width: 335px;
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
    overflow: hidden;
}

.Space-Between-DetailInfo {
    margin-bottom: 40px;
}

.Space-Between-MonitoringResult {
    margin-bottom: 20px;
}

.MonitoringResult-Transect-Info-Name-Rect
{
    margin-bottom: 0px;
}

.MonitoringResult-Transect-Info-Value-Rect {
    margin-top: 2px;
}

@include media-breakpoint-up(sm) {
    .Monitoring-Container {
        margin-top: 30px;
        max-width: 1187px;
    }

    .Monitoring-Container-Main {
        margin: 0px;
        width: 767px;
        max-width: 767px;
    }

    #title-img.Rectangle {
        width: 100%;
        height: 570px;
        max-height: 570px;
        border-radius: 10px;
        object-fit: cover;
        overflow: hidden;
    }

    .Space-Between-DetailInfo {
        margin-bottom: 71px;
    }

    .nav-tabs .nav-link {
        border: none;
        border-bottom: 3px solid transparent;
        margin-top: 0px;
        margin-right: 28px;
        padding-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
        font-family: 'Spoqa Han Sans' !important;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #000;
    }
}

.photo-image, .meta-image {
    width: 100%;
    height: 80px;
    min-width: 80px;
    min-height: 80px;
    object-fit: cover;
    border-radius: 10px;
    overflow: hidden;
}

@include media-breakpoint-up(sm) {
    .photo-image, .meta-image {
        width: 100%;
        height: 180px;
        max-width: 180px;
        max-height: 180px;
        object-fit: cover;
        border-radius: 10px;
        overflow: hidden;
    }
}

.monitoring-result-page-section-title {
    text-align: left;
}

.Weather-Title,
.DetailInformation {
    margin-bottom: 11px;
    font-family: "Noto Sans KR" !important;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #000;
}

.Photo,
.MonitoringResult {
    margin-top: 30px;
    // margin-bottom: 11px;
    font-family: 'Spoqa Han Sans' !important;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #303030;
}

.Monitoring-Result-Section-Seperator {
    margin-top: 30px;
    background-color: #e4e9f2;
}

.Monitoring-Result-Loadmore {
    height: 40px;
    margin-top: 24px !important;
    border-radius: 4px !important;
    padding-left: 16.5px !important;
    padding-right: 16.5px !important;
    border: solid 1px #e1e1e1 !important;
    color: #000 !important;
    background-color: #fff !important;
}

@include media-breakpoint-down(sm) {
    .Photo,
    .MonitoringResult {
        margin-top: 45px;
        // margin-bottom: 11px;
        font-family: 'Spoqa Han Sans' !important;
        font-size: 22px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #303030;
    }

    .Monitoring-Result-Section-Seperator {
        margin-top:45px;
        margin-bottom: 0px;
        background-color: #e4e9f2;
    }
}

.MonitoringResult-Shadow {
    width: 100%;
    margin-top: 14px;
    margin-bottom: 26px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(48, 49, 51, 0.06);
    background-color: #fff;
}

@include media-breakpoint-down(sm) {
    .MonitoringResult-Shadow {
        width: 100%;
        margin-top: 12px;
        margin-bottom: 26px;
        border-radius: 10px;
        box-shadow: 0 0 10px 0 rgba(48, 49, 51, 0.06);
        background-color: #fff;
    }
}

.TotalNumber-title,
.Weight-title {
    margin-top: 6px;
    margin-bottom: 5px;
    font-family: "Noto Sans KR" !important;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #8f9bb3;
}

.TotalNumber-value,
.Weight-value {
    margin-top: 5px;
    margin-bottom: 7px;
    font-family: "Noto Sans KR" !important;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #000;
}

.Weather-Shadow,
.LatestTimeOfHighTide-Shadow,
.Temperature-Shadow,
.WindDirection-Shadow,
.WindSpeed-Shadow,
.StartTime-Shadow,
.EndTime-Shadow,
.NameOfTeamLeader-Shadow {
    height: 100%;
    padding-top: 25px;
    padding-bottom: 25px;
    border-radius: 10px;
    background-color: #fafafa;
    display: flex;
    flex-direction: column;
    margin-right: 10px;
}


.Weather-Row,
.DetailInformation-Row {
    margin-bottom: 30px
}

.Temperature-title,
.WindDirection-title,
.WindSpeed-title,
.Weather-Card-Title,
.LatestTimeOfHighTide-title,
.NameOfTeamLeader-title,
.StartTime-title,
.EndTime-title {
    margin-top: 10px;
    font-family: "Noto Sans KR" !important;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #8f9bb3;
}

.Temperature-value,
.WindDirection-value,
.Weather-value,
.LatestTimeOfHighTide-value,
.StartTime-value,
.EndTime-value {
    font-family: "Noto Sans KR" !important;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #000;
    margin-bottom: auto;
}

.WindSpeed-value,
.NameOfTeamLeader-value {
    font-family: "Noto Sans KR" !important;
    padding-top: 10px;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #000;
    margin-bottom: auto;
}

.sun-1 {
    width: 36px;
    height: 36px;
}

.Monitoring-Sidebar-Shadow {
    padding: 24px;
    border-radius: 10px;
    box-shadow: 0 10px 10px 0 rgba(48, 49, 51, 0.06);
    background-color: #fff;
    margin-top: 0px;
    margin-bottom: 40px;
}

.Photo-Rect-End, .Photo-Rect {
    display: flex;
    flex-direction: column;
}

.Photo-Rect {
    margin-right: 11px;
}


.Meta-Rect-End, .Meta-Rect {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: 1px #eee solid;

    -webkit-transition:-webkit-box-shadow 0.6s ease-out, opacity 0.6s ease-out;
    -moz-transition:-moz-box-shadow 0.6s ease-out, opacity 0.6s ease-out;
    transition:box-shadow 0.6s ease-out, opacity 0.6s ease-out;
}

.Meta-Rect {
    margin-right: 11px;
}

.Meta-Rect-End:hover, .Meta-Rect:hover {
    -webkit-box-shadow:2px 3px 2px lightgray;
    -moz-box-shadow:2px 3px 2px lightgray;
    box-shadow:2px 3px 2px lightgray;
    opacity:10;
    cursor:pointer;
}

@include media-breakpoint-up(sm) {
    
    .Monitoring-Sidebar-Shadow {
        padding: 24px;
        border-radius: 10px;
        box-shadow: 0 10px 10px 0 rgba(48, 49, 51, 0.06);
        background-color: #fff;
    }
}

#tab-wrap nav {
    flex-wrap: nowrap !important;
    white-space: nowrap !important;
    overflow-x: auto !important;
    overflow-y: hidden !important;
    -webkit-overflow-scrolling: touch;
}

#tab-wrap nav a {
    display: inline-block;
}

#tab-wrap .nav-tabs .nav-link {
    margin-bottom: 0px !important;
    display: inline-block !important;
}

.Monitoring-Sidebar-Title {
    border-bottom: 1px #f2f2f2;
    font-family: "Noto Sans KR" !important;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #000;
}

.Monitoring-Sidebar-Fill {
    height: 1px;
    margin-top: 17px;
    margin-bottom: 7px;
    background-color: #f2f2f2;
}

.Monitoring-Sidebar-Date {
    margin-top: 18px;
    margin-bottom: 0px;
}

.Monitoring-Sidebar-Text {
    margin-top: 2px;
    margin-bottom: 0px;
    font-family: 'Spoqa Han Sans' !important;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #000;
}

.Monitoring-Sidebar-Orgranization {
    margin-top: 18px;
    margin-bottom: 0px;
    font-family: 'Spoqa Han Sans' !important;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #8f9bb3;
}

.Monitoring-Sidebar-Site {
    margin-top: 18px;
    margin-bottom: 0px;
    font-family: 'Spoqa Han Sans' !important;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #8f9bb3;
}

.Monitoring-Sidebar-NumberOfParticipan {
    margin-top: 18px;
    margin-bottom: 0px;
    font-family: 'Spoqa Han Sans' !important;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #8f9bb3;
}

// .Monitoring-Sidebar-Others {
//     margin-top: 18px;
//     margin-bottom: 0px;
//     font-family: 'Spoqa Han Sans' !important;
//     font-size: 12px;
//     font-weight: bold;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 1.5;
//     letter-spacing: normal;
//     color: #8f9bb3;
// }

.Monitoring-Sidebar-SiteGPSInformation {
    margin-top: 18px;
    margin-bottom: 0px;
    font-family: 'Spoqa Han Sans' !important;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #8f9bb3;
}

.Monitoring-Sidebar-Longitude {
    margin-top: 9px;
    margin-bottom: 0px;
    width: 65px;
    display: inline-block;
    font-family: 'Spoqa Han Sans' !important;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #909090;
}

.Monitoring-Sidebar-Latitude {
    margin-top: 9px;
    margin-bottom: 0px;
    width: 65px;
    display: inline-block;
    font-family: 'Spoqa Han Sans' !important;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #909090;
}

.MonitoringResult-Transect-Weight-Ratio-Text,
.MonitoringResult-Transect-Number-Ratio-Text {
    margin-top: 26px;
    font-family: "Noto Sans KR" !important;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #909090;
}

.MonitoringResult-DL-Button {
    width: 160px;
}

.top-10-table {
    margin-top: 50px;
}

.top-10-table .table > .custom_table_head > tr > th:nth-child(1) {
    width: 5%;
}

.top-10-table .table > .custom_table_head > tr > th:nth-child(2) {
    width: 60%;
}

.top-10-table .table > .custom_table_head > tr > th:nth-child(3) {
    width: 15%;
}

.top-10-table .table > .custom_table_head > tr > th:nth-child(4) {
    width: 20%;
}