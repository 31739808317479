@import "../../bootstrap.scss";

.FileUpload-Input-Text {
    /* width: 120px;
    height: 120px;
    max-width: 120px;
    max-height: 120px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.FileUpload-Input-Image {
    /* width: 100%;
    height: auto;
    max-width: 120px;
    max-height: 120px; */
}

/* .thumnail-fit-width {
    object-fit: cover;
    opacity: unset;
    border: 1px solid #ced4da;
    padding:unset;
} */

.FileUpload-Input-Image-Rect {
    /* width: 100%;
    max-width: 120px; */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.FileUpload-Input {
    /* width: 100%;
    height: auto;
    max-width: 120px;
    max-height: 120px; */
    border-radius: 5px;
    border: solid 1px #e4e9f2;
    background-color: #fff;
    position: relative;
    text-align: center;
    overflow: hidden;
    vertical-align: middle;
}


.Photo-Before-Image-Rect,
.Photo-After-Image-Rect,
.Photo-MonitoringCard-Image-Rect
 {
    width: 120px !important;
    height: 120px !important;
    max-width: 120px !important;
    max-height: 120px !important;
    border-radius: 5px !important;
}

.Photo-Transect-Image-Rect {
    width: 60px !important;
    height: 60px !important;
    max-width: 60px !important;
    max-height: 60px !important;
    border-radius: 5px !important;
}

.Photo-Transect-Image-Rect-Disabled {
    background-color: #e9ecef !important;
    opacity: 1;
    width: 60px !important;
    height: 60px !important;
    max-width: 60px !important;
    max-height: 60px !important;
    border-radius: 5px !important;
}


.FileUpload-Input img {
    height: 100% !important;
    width: auto !important;
    border-radius: 5px;
    margin: 0 auto;
    object-fit: cover;
}

.FileUpload-Input input {
    cursor: pointer;
    opacity: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0
}

.meta-info-file-name-rect {
    position: absolute;
    margin: 20px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: left;
    overflow-wrap: break-word;
    justify-content: center;
    overflow: hidden;
    font-family: SpoqaHanSans;
    font-size: 13px;
    flex-direction: column;
    display: flex;
}

.meta-info-file-name {
    overflow: hidden;
    cursor: default;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@include media-breakpoint-down(sm) {
    .Photo-Before-Image-Rect,
    .Photo-After-Image-Rect
     {
        width: 100% !important;
        height: 120px !important;
        max-width: 100% !important;
        max-height: 120px !important;
        border-radius: 5px !important;
    }

    .Photo-MonitoringCard-Image-Rect
    {
       width: 78px !important;
       height: 78px !important;
       max-width: 78px !important;
       max-height: 78px !important;
       border-radius: 5px !important;
   }

    .Photo-Transect-Image-Rect {
        width: 51px !important;
        height: 51px !important;
        max-width: 51px !important;
        max-height: 51px !important;
        border-radius: 5px !important;
    }
    
    .Photo-Transect-Image-Rect-Disabled {
        background-color: #e9ecef !important;
        opacity: 1;
        width: 51px !important;
        height: 51px !important;
        max-width: 51px !important;
        max-height: 51px !important;
        border-radius: 5px !important;
    }
}