.form-select-site {
    width: 200px;
}

/* On screens that are less than 576px wide */
@media (max-width: 576px) {
    #data-site-combo {
        margin-top: 12px;
        margin-bottom: 20px;
        width: 100%;
        font-family: 'Spoqa Han Sans' !important;
        font-size: 16px;
    }
}

/* On screens that are more than 576px wide */
@media (min-width: 576px) {
    #data-site-combo {
        width: 186px;
        float: right;
        font-family: 'Spoqa Han Sans' !important;
        font-size: 16px;
    }
}

.option_text {
    font-family: 'Spoqa Han Sans' !important;
    font-size: 16px;
}

.custom-modal {

}

.custom-modal .modal-content {
    width: 320px;
    height: 204px;
    left: calc(50% - 160px);
    overflow-y: auto;
}

.custom-modal-close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.custom-modal-header {
    font-family: 'Spoqa Han Sans' !important;
    font-size: 16px;
    font-weight: bold;

    position: absolute;
    top: 20px;
    left: 20px;
}

.custom-modal-body {
    font-family: 'Spoqa Han Sans' !important;
    font-size: 14px;
    color: #6b6b6b;

    position: absolute;
    top: 60px;
    left: 24px;
    width: 280px;
    height: 60px;
}

.custom-modal-footer {
    position: absolute;
    top: 152px;
    right: 22px;
}

.custom-modal-button {
    font-family: 'Spoqa Han Sans' !important;
    font-size: 14px !important;
    font-weight: bold !important;
}

.Import-Excel {
    width: 100%;
    max-width: 180px;
    height: 40px;

    padding: 8px 0px;
    border-radius: 4px;

    font-family: 'Spoqa Han Sans' !important;
    font-size: 16px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    text-align: center !important;

    color: #fff !important;
    background-color: #35c4f0 !important;
    border-color: #35c4f0 !important;
}