.TransectResult-Data-Title-Row {
    margin-top: 30px;
}

.TransectResult-Material-Hr {
    margin-top: 40px;
    margin-bottom: 10px;
    color: #e1e1e1;
}

.TransectResult-Data-Title {
    font-family: "Noto Sans KR";
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #000;
}

.TransectResult-Data-Weight-Rect,
.TransectResult-Data-Number-Rect {
    margin-top: 10px;
    border-radius: 4px;
    border: solid 1px #e4e9f2;
    background-color: #f7f9fc;
}

.TransectResult-Data-Weight,
.TransectResult-Data-Number {
    margin-left: 20px;
    margin-top: 15px;
    margin-bottom: 14px;
    font-family: SpoqaHanSans;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: #8f9bb3;
}

.TransectResult-Data-Weight-Value,
.TransectResult-Data-Number-Value {
    margin-top: 15px;
    margin-bottom: 15px;
    margin-right: 20px;
    vertical-align: middle;
    font-family: SpoqaHanSans;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: right;
    color: #000;
}

.TransectResult-Data-SubTitle {
    margin-top: 20px;
    font-family: "Noto Sans KR";
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.69;
    letter-spacing: normal;
    color: #000;
}

.TransectResul-Item-Header-Row {
    margin-top: 11px !important;
    padding: 5px 12px 7px 12px !important;
    border-bottom: 1px solid #e4e9f2;
}

.TransectResult-Data-Column-Items {
    font-family: SpoqaHanSans;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #718096;
}

.TransectResult-Data-Column-Number {
    width: 94px;
    font-family: SpoqaHanSans;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #718096;
}

.TransectResult-Data-Row {
    border-bottom: 1px solid #eff4fb;
    padding-top: 13px;
    padding-bottom: 13px;
}

.TransectResult-Data-Row-Item {
    font-family: SpoqaHanSans;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #454545;
}

.TransectResult-Data-Row-Number {
    width: 94px;
    text-align: left;
    font-family: SpoqaHanSans;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #454545;
}

.TransectResult-Statistics-Piechart-Legend {
    padding-left: 1rem; 
    padding-bottom: 7px;
    padding-top: 7px;
    text-indent: -.7em;
}

.TransectResult-Statistics-Piechart-Legend::before{
    content: "●";
    padding-right: 0.5rem; 
    color: var(--dot-color);
}