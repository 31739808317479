.create-button-container {
    width: 100px;
    float: right;
}

.custom_table_head {
    font-family: 'Spoqa Han Sans';
    color:#718096;
    font-size: 12px;
    font-weight: bold;
    vertical-align: middle !important;
}

.custom_table_head_item {
    min-width: 120px;
}

.custom_table_body {
    font-family: 'Spoqa Han Sans';
    color:#454545;
    font-size: 14px;
    vertical-align: middle !important;
}

.custom_table_item_bold {
    font-family: 'Spoqa Han Sans' !important;
    color:#454545;
    font-size: 14px;
    font-weight: bold;
    vertical-align: middle !important;
}

.custom_table_item_regular {
    font-family: 'Spoqa Han Sans' !important;
    color:#454545;
    font-size: 14px;
    vertical-align: middle !important;
}

#mypage_title {
    font-family: 'Spoqa Han Sans';
    font-size: 24px;
    font-weight: bold;
}
