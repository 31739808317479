
.main-contents {
    max-width: 1240px;
}
.statistics-contents-title {
    height: 36px;
    font-family: 'Spoqa Han Sans';
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #303030;
    display: inline-block;
    text-align: left;
    width: 109px;
    margin-bottom: 15px;
    margin-left: -15px;
}

.statistics-nav-items-group {
    display: contents;
}

.statistics-nav-item-sel {
    width: 74px;
    height: 34px;
    /* padding: 7px 14px; */
    border-radius: 5px;
    background-color: #35c4f0;
    cursor: pointer;
    text-align: center;
    padding: 5px 0;
    margin-left: 6px;
}

.statistics-nav-item-sel:first-child {
    margin-left: 0px;
}

.statistics-nav-item-unsel {   
    width: 74px;
    height: 34px;
    /* padding: 7px 14px; */
    border-radius: 5px;
    background-color: #f5f9fc;
    cursor: pointer;
    margin-left: 6px;
    text-align: center;
    padding: 5px 0;
}

.statistics-nav-item-unsel:first-child {
    margin-left: 0px;
}

.statistics-nav-item-unsel-text {
    width: 45px;
    height: 20px;
    font-family: 'Spoqa Han Sans';
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #718096;
    /* vertical-align:middle;
    display: table-cell; */
}

.statistics-nav-item-sel-text {
    width: 30px;
    height: 20px;
    font-family: 'Spoqa Han Sans';
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #fff;
    /* vertical-align:middle;
    display: table-cell; */
}

.statistics-contents-label {
    width: 28px;
    height: 21px;
    /* margin: 0 284px 7px 0; */
    font-family: 'Spoqa Han Sans';
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #252525;
    padding-top: 28px;
}

/* .statistics-contents-wrapper {
    padding-left: 15px;
} */

.statistics-contents-types-group {
    float : left;
    display: flex;
}

.statistics-contents-combobox {
    height: 48px;
    border-radius: 4px;
    border: solid 1px #e4e9f2;
    background-color: #fff;
    margin-bottom: 10px;
    font-family: 'Spoqa Han Sans';
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    /*min-width: 200px; */
}

.statistics-contents-year-between-wrapper {
    display: contents;
}

.statistics-contents-year-between {
    width: 8px;
    height: 21px;
    margin-top: 9px;
    font-family: 'Spoqa Han Sans';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #909090;
    margin-left: -10px;
    margin-right: -10px;
}

.statistics-contents-download-btn {
    width: 201px;
    height: 48px;
    padding-top: 12px;
    border-radius: 4px;
    background-color: #35c4f0;
    font-family: 'Spoqa Han Sans';
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    color: #fff;
    display: inline-block;
}

.bar-chart-wrapper {
    overflow-y: hidden;
    overflow-x: var(--x-scroll);
    z-index: 0;
}

.scrollbar {
    margin-left: 30px;
    float: left;
    height: 300px;
    width: 65px;
    background: #fff;
    overflow-y: scroll;
    margin-bottom: 25px;
}
  
.scrollbar-primary::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: transparent; 
}
  
.scrollbar-primary::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #C5C5C5; 
}
  
.scrollbar-primary {
    scrollbar-color: #C5C5C5 #F5F5F5;
}

.pie-chart-wrapper{
    border-radius: 4px;
    border: solid 1px #e4e9f2;
}

.statistics-pie-chart-title{
    font-family: 'Spoqa Han Sans';
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #252525;
    /* margin-left: 18px; */
    margin-top: 18px;
    display: block;
}

.statistics-pie-chart-legend-wrapper{
    height: 340px;
    overflow-y: scroll;
}

.statistics-pie-chart-legend{
    padding-left: 1em; 
    text-indent: -.7em;
    font-family: 'NotoSansCJKkr';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    padding-bottom: 14px;
}

.statistics-pie-chart-legend::before{
    content: "●  ";
    color: var(--dot-color);
}

.statistics-bar-chart-title{

    padding-left: 20px;

    font-family: 'Spoqa Han Sans';
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 1.5; */
    letter-spacing: normal;
    color: #252525;
}