#custom-modal-sidebar .modal-content {
    height: 100%;
    overflow-y: auto;
}

#custom-modal-sidebar {
    left: calc(100% - 247px);
    width: 247px;
    min-height: 512px;
    height: 100%;
    margin: 0 !important;
    background-color: white;
}

#custom-sidebar-close-button {
    position: absolute;
    top: 24px;
    right: 16px;
    cursor: pointer;
}

#custom-sidebar-image-logo {
    position: absolute;
    left: 30px;
    top: 167px !important;
}

#custom-sidebar-brand {
    position: absolute;
    left: 30px;
    top: 200px !important;
    width: 200px;
}

#custom-sidebar-brand-text {
    font-family: 'Noto Sans KR';
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
}

.custom-sidebar-brand-hr {
    display: block !important;
    height: 1px !important;
    border: 0 !important; 
    border-top: 1px solid #e4e9f2 !important;
    padding: 0 !important;
    margin-top: 14px !important;
    margin-bottom: 0px !important;
    margin-right: 25px;
}

#custom-sidebar-nav {
    position: absolute;
    width: 100%;
    top: 278px !important;
}

.custom-sidebar-nav-menu-item {
    font-family: 'Noto Sans KR';
    font-size: 18px;
    color: #000000;
    margin-left: 30px;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.custom-sidebar-nav-menu-item:hover {
    color: #000000;
}

#custom-sidebar-logout-button {
    position: absolute;
    bottom: 46px;
}

.custom-sidebar-nav-menu-item-logout {
    font-family: 'Noto Sans KR';
    font-size: 14px;
    color: #718096;
    margin-left: 24px;
    margin-right: 24px;    
}

#navcustom-brand-logo {
    display: inline-block;
    margin-right: 8px;
    align-items: center;
    vertical-align: middle;
}

/* On screens that are less than 992px wide */
@media (max-width: 992px) {
    .custom-toggle-image {
        display: inline-block;
        cursor: pointer;
    }

    .navcustom-brand {
        font-family: 'Noto Sans KR';
        font-size: 14px;
        font-weight: bold;
        align-items: center;
        display: flex;
        vertical-align: middle;
        cursor: pointer;
    }

    #navcustom-brand-name {
        display: inline-block;
        width: 200px;
        height: 48px;
        white-space: pre-wrap;
        font-size: 14px;
    }

    .navcustom-menu-item-logout {
        font-family: 'Noto Sans KR';
        font-size: 14px;
        margin-left: 24px;
        margin-right: 24px;
    }
    
    .navcustom-menu-item-separator {
        margin-left: 1px;
        margin-right: 1px;
        display: none;
    }
    
    .navcustom-menu-item {
        font-family: 'Noto Sans KR';
        font-size: 18px;
        color: #000000;
        margin-left: 16px;
        margin-right: 16px;
    }
    
    .navcustom-menu a.active {
        font-size: 18px;
        font-weight: bold;
        border-bottom: 3px solid #35c4f0;
        margin-bottom: -3px;
    }
}

/* On screens that are more than 992px wide */
@media (min-width: 992px) {
    #navcustom-main {
        width: 100%;
    }

    .custom-toggle-image {
        display: none;
    }

    .navcustom-brand {
        font-family: 'Noto Sans KR';
        font-size: 18px;
        font-weight: bold;
        align-items: center;
        display: flex;
        cursor: pointer;
    }

    #navcustom-brand-name {
        display: inline-block;
        font-size: 18px;
    }

    .navcustom-menu-item-logout {
        font-family: 'Noto Sans KR';
        font-size: 14px;
        margin-left: 16px;
        margin-right: 16px;
    }
    
    .navcustom-menu-item-separator {
        margin-left: 1px;
        margin-right: 1px;
    }
    
    .navcustom-menu-item {
        font-family: 'Noto Sans KR';
        font-size: 18px;
        color: #000000;
        margin-left: 16px;
        margin-right: 16px;
    }
    
    .navcustom-menu a.active {
        font-size: 18px;
        font-weight: bold;
        border-bottom: 3px solid #35c4f0;
        margin-bottom: -3px;
    }
}
