.container-edit-member {
    position: relative;
    top: 15%;
    width: 343px;
    left: calc(50% - 171px);
    font-family: 'Spoqa Han Sans';
}

#edit_member_radio {
    font-family: 'Spoqa Han Sans';
    font-size: 14px;
}


#edit_member_first_name, #edit_member_last_name, #edit_member_password {
    font-family: 'Spoqa Han Sans';
    font-size: 16px;
    margin-top: 13px;    
}