@import "../../bootstrap.scss";

.card-img {
    width: 100%;
    height: 10em;
    object-fit: cover;
    overflow: hidden;
}


img.-Iconcalendar {
    width: 20px;
    height: 20px;
    margin-right: 4px;
    object-fit: contain;
    vertical-align: text-bottom;
}

img.-Iconpeople {
    width: 20px;
    height: 20px;
    margin-right: 4px;
    object-fit: contain;
    vertical-align: text-bottom;
}

.Monitoring-List-Card-Title {
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: "Noto Sans KR" !important;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #000;
}

.notosanskr-b-24 {
    font-family: "Noto Sans KR" !important;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
}

.MonitoringResultTitle {
    height: 160px;
    padding-top: 72px;
    position: relative;
    z-index: 5;

    font-family: "Noto Sans KR" !important;
    font-size: 36px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.33 !important;
    letter-spacing: normal !important;

    color: #fff;
}

.Monitoring-List-Card-Image-Rect {
    width: 100%;
    padding-top: 67%;
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 5px;
}

.MonitoringResult-Tab-Chart {
    padding-top: 43px;
}

.MonitoringResult-Tab-Chart-Legend {
    padding-top: 43px;
    height: 271px;
    margin-left: auto;
    margin-right: auto;
}

.Monitoring-List-Rectangle {
    height: 240px;
}

.Monitoring-List-Container-Rect {
    padding: 63px;
}