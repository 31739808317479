/* On screens that are less than 992px wide */
@media (max-width: 992px) {
    /* The side navigation menu */
    .sidebar {
        font-family: 'Spoqa Han Sans';
        font-size: 14px;
        padding: 0;
        background-color: #f7f9fc;
        overflow: auto;
        /* border: 1px solid black; */
        width: 100%;
        height: auto;
        position: relative;
    }

}

/* On screens that are more than 992px wide */
@media (min-width: 992px) {
    /* The side navigation menu */
    .sidebar {
        font-family: 'Spoqa Han Sans';
        font-size: 16px;
        padding: 0;
        background-color: #f7f9fc;
        overflow: auto;
        /* border: 1px solid black; */
        width: 100%;
        height: auto;
        position: relative;
    }
}
  
/* Sidebar links */
.sidebar a {
    display: block;
    color: #718096;
    padding: 16px;
    text-decoration: none;
    float: left;
}
  
/* Active/current link */
.sidebar a.active {
    /* background-color: #04AA6D; */
    color: black;
    font-weight: bold;
    border-bottom: 3px solid #35c4f0;
}
  
/* Links on mouse-over */
.sidebar a:hover:not(.active) {
    background-color: #555;
    color: white;
}
  
/* Page content. The value of the margin-left property should match the value of the sidebar's width property */
div.content {
    margin-left: 0;
    padding: 1px;
    height: 100%;
}
 
